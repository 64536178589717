/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ShippingMethod } from '@gemini-vsf/composables';
import { checkoutState } from '~/composables/useCheckout/checkoutState';
import dataManipulation from '~/helpers/dataManipulation';
import { CheckoutStep } from '~/types/enums';
import { setItem } from '~/helpers/asyncLocalStorage';
import { addressFromApiToForm, addressFormToApi } from '~/helpers/checkout/address';

const { findErrorInResponse } = dataManipulation();

export const checkoutShipping = (cartId: string) => {
  const checkout = checkoutState(cartId);
  /**
   * Sets the given shipping method on the cart and navigates to the billing step.
   * @param shippingMethod - The shipping method to set on the cart.
   * @param goToBilling - Whether to navigate to the billing step after setting the shipping method.
   */
  const setShippingMethodOnCart = async (shippingMethod: ShippingMethod, goToBilling = true) => {
    checkout.stepLoading.value = true;
    try {
      if (!checkout.cartIsVirtual.value) {
        await checkout.setShippingMethodOnCartComposable({
          shippingMethod: { carrier_code: shippingMethod.carrier_code, method_code: shippingMethod.method_code },
          customQuery: {
            setShippingMethodsOnCart: 'setShippingMethodsOnCartCustom',
          },
        });
        const setError = findErrorInResponse(checkout.shippingMethodErrors.value);
        if (setError) {
          checkout.notifyCheckoutError(CheckoutStep.Shipping, setError);
          console.error('setShippingMethodOnCart ~ setError:', setError);
          checkout.stepLoading.value = false;
          return;
        }

        await setItem(`method`, JSON.stringify(shippingMethod));
        checkout.checkoutShippingMethod.value = shippingMethod;
      }
      checkout.stepLoading.value = false;
      if (goToBilling) await checkout.router.push(`/${checkout.locale}/checkout/${CheckoutStep.Billing}`);
    } catch (error) {
      console.error('setShippingMethodOnCart ~ error:', error);
    }
    checkout.stepLoading.value = false;
  };

  /**
   * Sets the given address as the checkout shipping address.
   * @param address - The address to set as the checkout shipping address.
   * @param tryDefault - Whether to try to use the default shipping address if the given address is null or undefined.
   */
  const setCheckoutShipping = async (address: any, tryDefault = false) => {
    if (tryDefault) {
      // this happens only onMounted
      const defaultAddress = address.find((a: any) => a.default_shipping);
      if (defaultAddress) {
        checkout.checkoutShipping.value = defaultAddress;
        checkout.checkoutShippingAddressId.value = defaultAddress.id;
        await setItem(CheckoutStep.Shipping, defaultAddress);
      }
      return;
    }
    checkout.checkoutShipping.value = address;
    checkout.checkoutShippingAddressId.value = address.id;
    await setItem(CheckoutStep.Shipping, address);
  };

  /**
   * Sets the shipping address on the cart.
   * @param {boolean} [isNewAddress=false] - Indicates whether the shipping address is a new address or an existing one.
   * @throws Will throw an error if the request fails.
   * @returns {Promise<void>} A Promise that resolves when the shipping address has been successfully set on the cart.
   */
  const setShippingAddressOnCart = async (isNewAddress = false) => {
    checkout.stepLoading.value = true;
    try {
      const customerAddressId = checkout.checkoutShipping?.value?.id;
      await checkout.saveShippingAddress({
        params: {},
        shippingDetails: {
          ...(isNewAddress ? addressFormToApi(checkout.checkoutShipping.value) : addressFromApiToForm(checkout.checkoutShipping.value)),
          customerAddressId,
        },
      });
      const setError = findErrorInResponse(checkout.shippingErrors.value);
      if (setError) {
        checkout.notifyCheckoutError(CheckoutStep.Shipping, setError);
        checkout.checkoutAvailableShippingMethods.value = null;
        checkout.stepLoading.value = false;
        return;
      }
      checkout.checkoutAvailableShippingMethods.value = checkout.shippingData.value.available_shipping_methods;
    } catch (error) {
      console.error(error);
    }
    checkout.stepLoading.value = false;
  };

  return {
    setShippingMethodOnCart,
    setCheckoutShipping,
    setShippingAddressOnCart,
  };
};
