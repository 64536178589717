/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import dataManipulation from '~/helpers/dataManipulation';
import { onMounted } from '@nuxtjs/composition-api';
import { getItem, setItem } from '~/helpers/asyncLocalStorage';
import { CheckoutStep } from '~/types/enums';
import { CheckoutState } from '~/types/types';
import { checkoutState } from '~/composables/useCheckout/checkoutState';
import { checkoutShipping as checkoutShippingComposable } from '~/composables/useCheckout/checkoutShipping';
import { checkoutBilling as checkoutBillingComposable } from '~/composables/useCheckout/checkoutBilling';
import * as checkoutHelpers from './_helpers';

const { findErrorInResponse, getErrorDataFromPlaceResponse, getErrorDataForNotification } = dataManipulation();

/**
 * Returns an object containing the state of the checkout process.
 * @param cartId - The ID of the cart to use for the checkout process.
 * @param hasToMount
 * @returns An object containing the state of the checkout process.
 */
const useCheckout = (cartId: string, hasToMount = true): CheckoutState => {
  const checkout = checkoutState(cartId);
  const checkoutShipping = checkoutShippingComposable(cartId);
  const checkoutBilling = checkoutBillingComposable(cartId);

  /**
   * This function checks the validity of the current checkout step and updates the last valid step accordingly.
   * If the current step is not valid, it notifies the checkout error and redirects to the last valid step.
   *
   * @async
   */
  const checkStepValidity = async (): Promise<void> => {
    const checkoutUser = await getItem('user');
    const shipping = await getItem(CheckoutStep.Shipping);
    const billing = await getItem(CheckoutStep.Billing);
    const payment = await getItem(CheckoutStep.Payment);
    let lastValidStep = CheckoutStep.UserAccount;
    const isUserValid = !!checkoutUser || (checkout && checkout.checkoutUser.value);
    const isShippingValid = !!shipping || (checkout && checkout.checkoutShipping.value) || checkout.cartIsVirtual.value;
    const isBillingValid = !!billing || (checkout && checkout.checkoutBilling.value);
    const isPaymentValid = !!payment || (checkout && checkout.checkoutPayment.value);
    switch (checkout.currentStep.value) {
      case CheckoutStep.Shipping: {
        lastValidStep = isUserValid ? CheckoutStep.Shipping : CheckoutStep.UserAccount;
        break;
      }
      case CheckoutStep.Billing: {
        lastValidStep = isUserValid ? (isShippingValid ? CheckoutStep.Billing : CheckoutStep.Shipping) : CheckoutStep.UserAccount;
        break;
      }
      case CheckoutStep.Payment: {
        lastValidStep = isUserValid
          ? isShippingValid
            ? isBillingValid || isPaymentValid
              ? CheckoutStep.Payment
              : CheckoutStep.Billing
            : CheckoutStep.Shipping
          : CheckoutStep.UserAccount;
        break;
      }
      default: {
        lastValidStep = CheckoutStep.UserAccount;
      }
    }
    if (checkout.currentStep.value !== lastValidStep) {
      checkout.notifyCheckoutError('generic', 'invalidStep');
      await checkout.router.push(`/${checkout.locale}/checkout/${lastValidStep}`);
    }
  };

  /**
   * Sets the given user object as the checkout user and saves it to local storage.
   * @param userParam - The user object to set as the checkout user.
   */
  const setCheckoutUser = async (userParam: any) => {
    checkout.checkoutUser.value = userParam;
    await setItem(`user`, userParam);
  };

  /**
   * Sets the given address as the checkout shipping or billing address, depending on the given step.
   * @param {Object} address - The address to set as the checkout shipping or billing address.
   * @param {string} step - The step where the address should be set.
   * @param {boolean} [saveAddress=false] - Whether to save the address to the customer's address book.
   * @param {boolean} [setAsDefault=false] - Whether to set the address as the default shipping or billing address.
   * @throws Will throw an error if the request fails.
   * @returns {Promise<void>} A Promise that resolves when the address has been successfully set.
   */
  const setNewAddress = async (address: any, step: string, saveAddress = false, setAsDefault = false) => {
    if (saveAddress || setAsDefault) {
      await checkout.createCustomerAddress({
        address: {
          ...address,
          ...(setAsDefault ? { [`default_${step}`]: true } : {}),
        },
      });
      checkoutHelpers.handleCreateCustomerAddressResult(!checkout.addressErrors.value?.save, checkout.sendNotification, checkout.$gt);
      await checkout.loadUser();
    }
    if (step === CheckoutStep.Shipping) {
      await checkoutShipping.setCheckoutShipping(address);
      await checkoutShipping.setShippingAddressOnCart(true);
      return;
    }
    await checkoutBilling.setCheckoutBilling(address);
    await checkoutBilling.setBillingAddressOnCart(true);
  };

  /**
   * Handles the click event on a checkout step.
   * @param {number} step - The index of the step that was clicked.
   * @throws Will throw an error if the navigation fails.
   * @returns {Promise<void>} A Promise that resolves when the navigation to the clicked step has been successfully completed.
   */
  const handleStepClick = async (step: number) => {
    const stepString = Object.keys(checkout.STEPS)[step];
    await checkout.router.push(`/${checkout.locale}/checkout/${stepString}`);
  };

  const placeOrder = async () => {
    checkout.checkoutPlacingOrder.value = true;
    await checkout.placeOrderComposable({});
    checkout.checkoutPlacingOrder.value = false;
    const orderError = findErrorInResponse(checkout.orderErrors.value);
    if (orderError) {
      // Get response error code and message. If no code: return a string and print standard message
      const { code: errorCode, message: errorMessage } = getErrorDataFromPlaceResponse(checkout.orderErrors.value, orderError);
      if (!errorCode) {
        checkout.notifyCheckoutError(CheckoutStep.Payment, getErrorDataForNotification(orderError).key);
        return;
      }
      // Parse codes and messages to compose the notification content. Only 100 < code < 200 use original response message
      const { key, message } = getErrorDataForNotification(orderError, errorCode, errorMessage);
      // Pass a message string to override the content of the map. Used to print the original response message
      checkout.notifyCheckoutError(CheckoutStep.Payment, key, message);
      return;
    }
    checkout.setCart(null);
    checkout.geminiIntegrationState.setCartId();
  };

  const handleNotAuthorized = () => {
    checkout.redirect(401, checkout.app.localePath('/'));
    checkout.sendNotification({
      id: Symbol('cart_error'),
      message: checkout.$gt('You have no products in your shopping cart'),
      type: 'danger',
      icon: 'check',
      persist: false,
      title: checkout.$gt(`Not authorized`),
    });
  };

  onMounted(async () => {
    if (!checkout.user.value) {
      await checkout.loadUser();
    }
    if (!checkout.cart.value && hasToMount && checkout.route?.value?.path?.includes('checkout')) {
      await checkout.loadCart();
    }
    if (!checkout.cart.value?.items?.length && hasToMount && checkout.route?.value?.path?.includes('checkout')) {
      handleNotAuthorized();
    }
    const shippingFromCart = checkout.cart.value?.shipping_addresses?.[0];
    if (shippingFromCart) {
      await checkoutShipping.setCheckoutShipping({ ...shippingFromCart, country_code: shippingFromCart.country?.code });
    }
    if (checkout.user.value) {
      await setCheckoutUser(checkout.user.value);
      if (!checkout.checkoutShipping.value) {
        await checkoutShipping.setCheckoutShipping(checkout.user.value.addresses, true);
      }
      if (!checkout.checkoutBilling.value) {
        await checkoutBilling.setCheckoutBilling(checkout.user.value.addresses, true);
      }
    }
    checkout.checkoutLoading.value = false;
    checkout.stepLoading.value = false;
  });

  const setBillingCountry = (country: string) => {
    checkout.checkoutBillingCountry.value = country;
  };

  const setCheckoutPlaceOfBirth = (placeOfBirth: string) => {
    checkout.checkoutPlaceOfBirth.value = placeOfBirth;
  };

  const setCheckoutDateOfBirth = (dateOfBirth: string) => {
    checkout.checkoutDateOfBirth.value = dateOfBirth;
  };

  const setCheckoutFiscalCode = (fiscalCode: string) => {
    checkout.checkoutFiscalCode.value = fiscalCode;
  };

  const setBillingAdditionalDataValidation = (isValid: boolean) => {
    checkout.billingAdditionalDataValidation.value = isValid;
  };

  return {
    setCheckoutUser,
    setNewAddress,
    handleStepClick,
    checkStepValidity,
    placeOrder,
    setBillingCountry,
    setCheckoutPlaceOfBirth,
    setCheckoutDateOfBirth,
    setCheckoutFiscalCode,
    setBillingAdditionalDataValidation,
    ...checkout,
    ...checkoutShipping,
    ...checkoutBilling,
  };
};

export default useCheckout;
